import React from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

const Icon = loadable(() => import('common/components/styleguide/Icon'));

const StyledTextIcon = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ right }) => (right ? 0 : '5px')};
  margin-left: ${({ right }) => (right ? '5px' : 0)};
`;

export default ({ className = '', iconClassName = '', right = false, children, ...rest }) => {
  return right ? (
    <StyledTextIcon className={className}>
      {children}
      {iconClassName ? <StyledIcon className={iconClassName} right={true} /> : ''}
    </StyledTextIcon>
  ) : (
    <StyledTextIcon className={className}>
      {iconClassName ? <StyledIcon className={iconClassName} right={false} /> : ''}
      {children}
    </StyledTextIcon>
  );
};
