import React, { Fragment } from 'react';
import _ from 'lodash';

import Button from 'common/components/styleguide/Button';
import Icon from 'common/components/styleguide/Icon';
import TextIcon from 'common/components/styleguide/TextIcon';

import { ICON_CART_ADD } from 'common/constants/IconConstants';

import { capfl } from 'common/utils/index';

const AddToCartButtonVariant1 = ({
  product,
  onAddToCartClick,
  customStyle,
  className,
  iconClassName = ICON_CART_ADD,
  buttonSize,
  fullWidth,
  title,
  t
}) => {
  const { store = [], isOrderable = false } = product;
  const showAddToCartBtn =
    _.isArray(store) && store.length === 1 && isOrderable;
  if (!showAddToCartBtn) {
    return null;
  }
  return (
    <Button
      onClick={onAddToCartClick}
      buttonSize={buttonSize}
      fullWidth={fullWidth}
      className={className}
    >
      {iconClassName && title ? (
        <TextIcon
          iconClassName={iconClassName}
          customStyle={_.get(customStyle, 'icon')}
        >
          {capfl(t(title))}
        </TextIcon>
      ) : (
        <Fragment>
          <Icon
            className={iconClassName}
            customStyle={_.get(customStyle, 'icon')}
          />
          {title ? capfl(t(title)) : ''}
        </Fragment>
      )}
    </Button>
  );
};

export default React.memo(AddToCartButtonVariant1);
